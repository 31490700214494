// @flow
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import { API_BASE } from '../helpers';

export const READ_MARKETING_PAGE_REQUEST = 'READ_MARKETING_PAGE_REQUEST';
export const READ_MARKETING_PAGE_SUCCESS = 'READ_MARKETING_PAGE_SUCCESS';
export const READ_MARKETING_PAGE_FAILURE = 'READ_MARKETING_PAGE_FAILURE';

const loadMarketingPageRequest = () => {
  return {
    type: READ_MARKETING_PAGE_REQUEST
  };
};

const loadMarketingPageSuccess = (marketingPage): any => {
  return {
    type: READ_MARKETING_PAGE_SUCCESS,
    payload: marketingPage
  };
};

const loadMarketingPageFailure = (error): any => {
  return {
    type: READ_MARKETING_PAGE_FAILURE,
    error
  };
};

export const loadMarketingPage = (slug: string): any => async (
  dispatch: any,
  getState
) => {
  try {
    dispatch(loadMarketingPageRequest());
    return fetch(`${API_BASE}/wp-json/wp/v2/helix_marketing?slug=${slug}`)
      .then(
        response => (response.json(): Promise<Array<Object>>),
        // eslint-disable-next-line no-console
        (error: Error) => console.error('An error occured.', error)
      )
      .then(json => {
        // $FlowFixMe
        const camelizedJson = camelizeKeys(json[0], noCamelize);
        return dispatch(loadMarketingPageSuccess(camelizedJson));
      });
  } catch (error) {
    dispatch(loadMarketingPageFailure(error));
    return error;
  }
};
