// @flow
import { isNZHelix, isIndoHelix } from './index';

export const API_BASE =
  process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'prod'
    ? 'https://livecms.roymorgan.com'
    : process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'uat'
      ? 'https://livecms.roymorganuat.com'
      : 'https://livecms.roymorgandev.com';

export const getLiveCMSEndpointURI = (): string => {
  if (isNZHelix()) {
    return 'nz-helix/v1';
  }
  else if (isIndoHelix()) {
    return 'indo-helix/v1';
  }
  return 'helix/v1';
};

export const getLiveCMSBaseURL = (): string => {
  let namespace = getLiveCMSEndpointURI();

  return `${API_BASE}/wp-json/${namespace}`;
};
