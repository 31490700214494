// @flow
import type { Dispatch, ThunkAction } from '../types';

export function sendContact(
  contact: Object,
  subject: string,
  form: string,
  page: string
): ThunkAction {
  return async function(dispatch: Dispatch) {
    try {
      const body = JSON.stringify({
        contact,
        subject: `${subject} - ${window.location.href}`,
        form,
        page
      });
      let url = process.env.REACT_APP_CONTACT_API
        ? process.env.REACT_APP_CONTACT_API
        : 'https://liveapi.roymorgan.com/management/v2/emails/contact/';
      const response = await fetch(url, {
        method: 'POST',
        body
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
}
