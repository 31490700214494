// @flow
import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import type { Store } from 'redux';
import NavMenu from '../components/NavMenu';
import RootContent from './RootContent';

import Footer from 'roy-morgan-footer';

type Props = {
  store: Store<*, *>
};

class Root extends React.Component<Props> {
  render() {
    let { store } = this.props;

    return (
      <div>
        <Provider store={store}>
          <div>
            <Router>
              <div>
                <Route path="/" component={NavMenu} />
                <Route path="/" component={RootContent} />
                <Footer />
              </div>
            </Router>
          </div>
        </Provider>
      </div>
    );
  }
}

export default Root;
