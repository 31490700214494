// @flow strict

/*
 * Redux Form validations
 */

export const required = (value: string | number | boolean): void | string =>
  value ? undefined : 'Required';

export const phoneNumber = (value: string, previousValue: string): string => {
  const onlyNums = value.replace(/[^\d +()-]/g, '');
  return onlyNums;
};

export const validateEmail = (values: { email: string }): {email?: string} => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};
