// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import type { MetaTags } from '../types';

type Props = {
  metaTags: ?MetaTags
};

const Head = ({ metaTags }: Props) => {
  if (!metaTags) {
    return null;
  }
  return (
    <Helmet>
      {metaTags.title && <title>{metaTags.title}</title>}
      {metaTags.metaDesc && (
        <meta name="description" content={metaTags.metaDesc} />
      )}
      {metaTags.opengraphTitle && (
        <meta property="og:title" content={metaTags.opengraphTitle} />
      )}
      {metaTags.opengraphDescription && (
        <meta
          property="og:description"
          content={metaTags.opengraphDescription}
        />
      )}
      {metaTags.opengraphImage && (
        <meta property="og:image" content={metaTags.opengraphImage} />
      )}
      {metaTags.twitterTitle && (
        <meta property="twitter:title" content={metaTags.twitterTitle} />
      )}
      {metaTags.twitterDescription && (
        <meta
          property="twitter:description"
          content={metaTags.twitterDescription}
        />
      )}
      {metaTags.twitterImage && (
        <meta property="twitter:image" content={metaTags.twitterImage} />
      )}
    </Helmet>
  );
};

export default Head;
