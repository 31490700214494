// @flow
import * as React from 'react';
import type { Community as CommunityType } from '../../types';
import PersonaBanner from './PersonaBanner';
import CommunitySummaryTile from '../community/CommunitySummaryTile';
import CommunityCarousel from '../community/CommunityCarousel';
import { Container } from 'semantic-ui-react';
import { makePersonaURL } from '../../helpers/makeURL';

type Props = {
  welcomePage: Object,
  community: CommunityType,
};

const PersonaLanding = ({
  welcomePage,
  community,
}: Props): React.Element<typeof React.Fragment> => {

  return (
    <>
      <Container>
        <PersonaBanner community={community} welcomePage={welcomePage} />
        <div className="persona-tiles__grid">
          {community?.personas
            .sort(function (a, b) {
              return a.postTitle === undefined || b.postTitle === undefined
                ? 0
                : a.postTitle.toLowerCase() < b.postTitle.toLowerCase()
                ? -1
                : a.postTitle.toLowerCase() > b.postTitle.toLowerCase()
                ? 1
                : 0;
            })
            .map((persona, key) => (
              <CommunitySummaryTile
                key={key}
                id={persona.slug}
                name={persona.acf.title}
                image={persona.acf.featuredImage.url}
                isSummaryFixed
                summary={persona.acf.summaryExcerpt}
                // TODO: what's happening here?
                url={
                  makePersonaURL(community?.postTitle) +
                  '/' +
                  makePersonaURL(persona?.postTitle)
                }
                colour={community.acf.color}
                stat1img={persona.acf?.excerptIcon1?.image?.url}
                stat1label={persona.acf.excerptLabel1}
                stat1={persona.acf.excerptValue1}
                excerptNumberOnLeft1={persona.acf.excerptNumberOnLeft1}
                stat2img={persona.acf?.excerptIcon2?.image?.url}
                stat2label={persona.acf.excerptLabel2}
                stat2={persona.acf.excerptValue2}
                excerptNumberOnLeft2={persona.acf.excerptNumberOnLeft2}
                personaCarousel={true}
              />
            ))}
        </div>
        <CommunityCarousel welcomePage={welcomePage} fullWidth tall/>
      </Container>
    </>
  );
};

export default PersonaLanding;
