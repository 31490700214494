// @flow
import * as actions from '../actions/readMarketingPage';
import type { Action } from '../types';

type State = {
  isFetching: boolean,
  error?: Object,
  pages: Object
};

const initialState = {
  isFetching: false,
  error: undefined,
  pages: {}
};

export const marketingPages = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.READ_MARKETING_PAGE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.READ_MARKETING_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          // $FlowFixMe
          [action.payload && action.payload.slug]: action.payload
        },
        isFetching: false
      };
    case actions.READ_MARKETING_PAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
