// @flow
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import type { ThunkAction, Action } from '../types';
import { API_BASE } from '../helpers';

export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
function requestSettings(): Action {
  return {
    type: REQUEST_SETTINGS
  };
}

export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
function receiveSettings(json): Action {
  return {
    type: RECEIVE_SETTINGS,
    settings: json
  };
}

export function fetchSettings(): ThunkAction {
  return function(dispatch) {
    dispatch(requestSettings());

    return fetch(`${API_BASE}/wp-json/acf/v3/options/666`)
      .then(
        response => response.json(),
        // eslint-disable-next-line no-console
        error => console.error('An error occured in fetchSettings.', error)
      )
      .then(json => {
        const camelizedJson = camelizeKeys(json, noCamelize);
        if (camelizedJson && camelizedJson.acf) {
          return dispatch(receiveSettings(camelizedJson.acf));
        } else {
          return dispatch(receiveSettings(camelizedJson));
        }
      });
  };
}
