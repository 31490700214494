// @flow strict
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SocialMediaRowWithTitle from '../../components/PageContent/SocialMediaRowWithTitle';

const mapStateToProps = state => {
  return {
    socialMedia: state.settings.socialMedia
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SocialMediaRowWithTitle
);
