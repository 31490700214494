// @flow
import * as React from 'react';
import type {
  SocialMediaRow as PageContentType,
  SocialMedia
} from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: PageContentType,
  socialMedia: Array<SocialMedia>
};

class SocialMediaRowWithTitle extends React.PureComponent<Props> {
  render() {
    const { socialMedia } = this.props;
    return (
      <div className="page-row page-row--min-bottom-padding page-row--background-athens-grey follow-us">
        <h2 className="page-row__title text--cyan">Follow Us</h2>
        <div className="follow-us__flex">
          {socialMedia &&
            socialMedia.map((social, i) => (
              <a
                key={i}
                href={`${social.socialLink.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="follow-us__item-icon">
                  <img src={social.socialIcon.url} alt={social.name} />
                </div>
                {ReactHtmlParser(social.socialLink.title)}
              </a>
            ))}
        </div>
      </div>
    );
  }
}

export default SocialMediaRowWithTitle;
