// @flow
import * as React from 'react';

type Props = {
  id: string,
  name: string,
  img: string,
  colour: string,
};

const WelcomeBannerPersona = ({
  id,
  name,
  img,
  colour,
}: Props): React.Element<'div'> => {
  const title = id + ' | ' + name;
  return (
    <div
      className={`banner__persona banner__persona--span-2-col banner__persona--${id}`}
    >
      <div className="persona__content">
        <div className="content__title" style={{ backgroundColor: colour }}>
          <p className="title__text">
            {id}
            <span>{' | ' + name}</span>
          </p>
        </div>
        <div className="content__img-container">
          <img className="img-container__img" src={img} alt={title} />
        </div>
      </div>
    </div>
  );
};

export default WelcomeBannerPersona;
