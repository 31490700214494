// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Grid, Container, Button } from 'semantic-ui-react';
import Link from '../Link';
import type { SingleColumnRow as PageContentType } from '../../types';

type Props = {
  row: PageContentType
};

const SingleColumnRow = ({ row }: Props) => {
  const {
    title,
    content,
    button,
    backgroundColour,
    accentColour,
    reversedButton,
    largeButton
  } = row;

  let buttonColour;

  accentColour !== 'none' && accentColour !== 'cyan'
    ? (buttonColour = accentColour)
    : (buttonColour = undefined);

  return (
    <div
      className={`page-row page-row--background-${
        backgroundColour
      } single-column`}
    >
      <Container>
        <Grid centered stackable>
          <Grid.Column tablet={10} computer={8}>
            <h2
              className={`text--${
                accentColour
              } page-row__title--single-column-row`}
            >
              {ReactHtmlParser(title)}
            </h2>
            {ReactHtmlParser(content)}
            {button !== '' &&
              typeof button !== 'string' && (
                <div className="page-row__buttons">
                  <Button
                    as={Link}
                    target={button.target}
                    url={button.url}
                    color={buttonColour}
                    inverted={reversedButton}
                    size={largeButton ? 'large' : undefined}
                  >
                    {ReactHtmlParser(button.title)}
                  </Button>
                </div>
              )}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default SingleColumnRow;
