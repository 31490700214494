// @flow

import * as React from 'react';
import InlineSvg from './InlineSvg';

type Props = {
  name: string,
  color?: string
};

const PageTitleIcon = (props: Props) => {
  const { name } = props;

  switch (name) {
    // Article icon is a PNG
    case 'article':
      return <div className="page-title__icon page-title__icon--article" />;

    // All others are SVGs
    default:
      return (
        <div className="page-title__icon">
          <InlineSvg {...props} />
        </div>
      );
  }
};

export default PageTitleIcon;
