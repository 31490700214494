// @flow strict
import React, { useEffect, useRef, useState } from 'react';
import InlineSvg from '../InlineSvg';
import type { InfoTabType } from '../../types';
import LoadSVG from '../LoadSVG';

type Props = {
  row: InfoTabType,
};

const InfoTab = ({ row }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [swapping, setSwapping] = useState(false);
  const [height, setHeight] = useState(0);
  const [fetchTabHeight, setFetchTabHeight] = useState(false);
  const [switchHeight, setSwitchHeight] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  // ref objects for setting container height
  const contentRef = useRef();
  const switchesRef = useRef();
  const switchRef = useRef();

  // onClick function to swap tabs
  const switchTabIndex = (tabNumber) => {
    setTabIndex(tabNumber);
    setSwapping(true);
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  useEffect(() => {
    const onResize = () => {
      if (
        contentRef?.current?.offsetHeight &&
        switchRef?.current?.offsetHeight
      ) {
        setOpenMenu(false);
        setHeight(
          contentRef?.current?.offsetHeight + switchRef?.current?.offsetHeight
        );
      }
      if (switchRef?.current?.offsetHeight && switchRef) {
        setSwitchHeight(switchRef?.current?.offsetHeight);
      }
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    // finds container height on load
    if (
      !fetchTabHeight &&
      row &&
      contentRef?.current?.offsetHeight &&
      switchRef?.current?.offsetHeight
    ) {
      setHeight(
        contentRef?.current?.offsetHeight + switchRef?.current?.offsetHeight
      );
      if (!fetchTabHeight && switchRef?.current?.offsetHeight) {
        setSwitchHeight(switchRef?.current?.offsetHeight);
      }
      setFetchTabHeight(true);
    }
    // triggers container height change when tabs swap
    if (swapping === true) {
      setHeight(
        contentRef?.current?.offsetHeight + switchRef?.current?.offsetHeight
      );
      setSwapping(false);
    }

    if (openMenu) {
      setHeight(
        contentRef?.current?.offsetHeight + switchesRef?.current?.offsetHeight
      );
      if (switchesRef?.current?.offsetHeight) {
        setSwitchHeight(switchesRef.current.offsetHeight);
      }
    } else if (!openMenu) {
      if (switchRef?.current?.offsetHeight) {
        setSwitchHeight(switchRef?.current?.offsetHeight);
      }
    }
  }, [swapping, fetchTabHeight, openMenu, row]);

  return (
    <div className="info-tab__wrapper">
      <div className="info-tab__wrapper-inner">
        <div className="info-tab__title">{row?.title}</div>
        <p className="info-tab__intro-text">{row?.introText}</p>
        <div className="info-tab__container" style={{ height: `${height}px` }}>
          <div className="info-tab">
            <div
              className="info-tab__switch-wrapper"
              style={{ height: `${switchHeight}px` }}
            >
              <span className={`info-tab__toggle ${openMenu ? 'open' : ''}`}>
                <InlineSvg name="chevron" colour="personas" />
              </span>
              <div className="info-tab__switches" ref={switchesRef}>
                {row?.tabRepeater &&
                  row?.tabRepeater.map((tabSwitch, i) => {
                    return (
                      <button
                        key={i}
                        className={`info-tab__switch  ${
                          tabIndex === i ? 'currentTab' : ''
                        }`}
                        onClick={() => {
                          switchTabIndex(i);
                        }}
                        ref={switchRef}
                      >
                        {tabSwitch?.tabSwitchText}
                      </button>
                    );
                  })}
              </div>
            </div>

            <div className="info-tab__content-grid-container" ref={contentRef}>
              {row?.tabRepeater &&
                row?.tabRepeater.map((tabContentBlock, i) => {
                  return (
                    <div
                      key={i}
                      className={`info-tab__content-grid 
                          ${tabIndex === i ? 'current' : ''}`}
                    >
                      {tabContentBlock.tabContentRepeater &&
                        tabContentBlock.tabContentRepeater.map(
                          (tabContent, j) => {
                            return (
                              <div key={j} className="info-tab__content-block">
                                <div className="info-tab__content-block--img">
                                  <LoadSVG
                                    src={tabContent?.tabContentIcon?.url}
                                    width="auto"
                                    colour="#00acca"
                                    stroke="#00acca"
                                    alt="tab icon"
                                  />
                                </div>
                                <h3 className="content-block__heading">
                                  {tabContent?.tabContentTitle}
                                </h3>
                                <div className="content-block__text">
                                  {tabContent?.tabContentText}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTab;
