// @flow

import * as React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { type IconSteps as ContentType } from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: ContentType
};

const IconSteps = (props: Props) => {
  const { iconStepsRepeater } = props.row;

  return (
    <div className="page-row ">
      <h2 className="page-row__title text--cyan margin--bottom-30">
        How does it work?
      </h2>
      <Container>
        <Grid stackable centered>
          {iconStepsRepeater.map((step, i) => (
            <Grid.Column tablet={6} computer={3} key={`icon-${i}`}>
              <div className="icon-steps__circle-container">
                <img
                  className="icon-steps__icon"
                  src={step.icon.url}
                  alt={step.icon.alt}
                />
                <svg
                  viewBox="0 0 88 88"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-steps__circle"
                >
                  <circle
                    className="icon-steps__circle-track"
                    cx="44"
                    cy="44"
                    r="40"
                  />
                  <circle
                    className="icon-steps__circle-progress"
                    cx="44"
                    cy="44"
                    r="40"
                    strokeDasharray="252"
                    strokeDashoffset={
                      252 - (i + 1) / iconStepsRepeater.length * 252
                      /* <%= 252 - @program_data.progress_tracker.weekly_score.to_f / 28.to_f * 252 %> */
                    }
                  />
                </svg>
              </div>
              <div className="icon-steps__text">
                {ReactHtmlParser(step.text)}
              </div>
            </Grid.Column>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
export default IconSteps;
