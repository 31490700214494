// @flow

import * as React from 'react';
import { Form, Button } from 'semantic-ui-react';
import SemanticReduxFormField from './SemanticReduxFormField';
import { Field, reduxForm } from 'redux-form';
import { required, phoneNumber, validateEmail } from '../helpers';

type Props = {
  title: string,
  buttonText: string,
  success: boolean,
  error: boolean,
  submitted: boolean,
  onSubmit: (v: Object) => void,
  handleSubmit: (f: Function) => void,
};

class CallbackFormCallout extends React.Component<Props> {
  render() {
    const dropdownOptions = [
      {
        value: 'anytime',
        text: 'Anytime',
      },
      {
        value: 'now',
        text: 'Now',
      },
      {
        value: 'morning',
        text: 'Morning',
      },
      {
        value: 'afternoon',
        text: 'Afternoon',
      },
    ];

    const { onSubmit, success, error, submitted, handleSubmit } = this.props;
    const title = 'Request a Callback';
    const buttonText = 'Contact me';

    return (
      <div className="callout callback-form-callout">
        <h4 className="callback-form-callout__title">{title}</h4>
        {!submitted || (submitted && error) ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Field
              name="name"
              component={SemanticReduxFormField}
              as={Form.Input}
              label="Name"
              required
              validate={required}
              maxLength={60}
            />
            <Field
              name="email"
              type="email"
              component={SemanticReduxFormField}
              as={Form.Input}
              label="Email"
              required
              validate={required}
              maxLength={60}
            />
            <Field
              name="phone"
              component={SemanticReduxFormField}
              as={Form.Input}
              label="Phone"
              required
              validate={required}
              normalize={phoneNumber}
              type="tel"
              maxLength={20}
            />
            <Field
              name="bestTime"
              component={SemanticReduxFormField}
              as={Form.Dropdown}
              placeholder="Please select"
              label="Best time to call"
              options={dropdownOptions}
              selection
              required
              validate={required}
            />
            {error && (
              <div className="form__response">
                There was an error with your request. Please try again.
              </div>
            )}
            <Button>{buttonText}</Button>
          </Form>
        ) : null}
        {success && (
          <div className="form__response">
            Thank you, a member of our team will be in touch with you soon
          </div>
        )}
      </div>
    );
  }
}

export default (reduxForm({
  form: 'callback-form-callout',
  validate: validateEmail,
})(CallbackFormCallout): React.ComponentType<any>);
