// @flow
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { isUrlAbsolute } from '../helpers';

type Props = {
  /** This is either the relative or absolute URL */
  url: string,
  /** Elements to be displayed inside the link */
  children?: React.Node
};

class Link extends React.PureComponent<Props> {
  render() {
    const { url, children, ...rest } = this.props;
    if (url) {
      if (isUrlAbsolute(url)) {
        return (
          <a {...rest} href={url}>
            {children}
          </a>
        );
      }
      return (
        <ReactRouterLink {...rest} to={url}>
          {children}
        </ReactRouterLink>
      );
    } else {
      return '';
    }
  }
}

export default Link;
