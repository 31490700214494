// @flow
import * as React from 'react';
import { Container, Image } from 'semantic-ui-react';
import type { ClientLogos as ClientLogosType } from '../../types';

type Props = {
  row: ClientLogosType
};

class ClientLogos extends React.Component<Props> {
  render() {
    const { clientLogosGallery } = this.props.row;

    return (
      <div className="page-row page-row--background-white">
        <Container>
          <div className="client-logos">
            {typeof clientLogosGallery !== 'boolean' &&
              clientLogosGallery.map((logo, i) => (
                <div key={logo.id}>
                  <Image src={logo.url} alt={logo.alt} />
                </div>
              ))}
            <div>&nbsp;</div>{' '}
            {/* Empty divs required to keep flex children aligning to the left if in an incomplete row - do not delete */}
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
        </Container>
      </div>
    );
  }
}

export default ClientLogos;
