// @flow
import * as React from 'react';
import PersonaCarousel from './community/PersonaCarousel';
import type { Persona } from '../types';
import CommunityCarousel from './community/CommunityCarousel';

type Props = {
  personas?: Persona[],
  title: string,
  page: Object,
  personaHeading: boolean,
};

const HelixCarousel = ({ personas, title, page, personaHeading }: Props) => {
  return (
    <div className="persona__carousel-wrapper">
      <div className="persona__carousel-heading">
        {personaHeading ? (
          <h2>View other {title} Personas</h2>
        ) : (
          <h2>VIEW OTHER COMMUNITIES</h2>
        )}
      </div>
      {personas ? (
        <PersonaCarousel fullWidth products={personas} welcomePage={page} />
      ) : (
        <CommunityCarousel fullWidth welcomePage={page} />
      )}
    </div>
  );
};

export default HelixCarousel;
