// @flow
import * as React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import type { FeatureGrid as ContentType } from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: ContentType
};

class FeatureGrid extends React.Component<Props> {
  render() {
    const { featureTitle, featureGridRepeater } = this.props.row;

    return (
      <div className="page-row page-row--no-horiz-margins page-row--min-bottom-padding page-row--background-white">
        <h2 className="page-row__title text--blue">
          {ReactHtmlParser(featureTitle)}
        </h2>
        <Container>
          <div className="feature-grid">
            <Grid stackable centered>
              {featureGridRepeater.map((feature, i) => (
                <Grid.Column tablet={6} computer={4} key={`feature-${i}`}>
                  <h4 className="text--cyan">{feature.title}</h4>
                  {ReactHtmlParser(feature.text)}
                </Grid.Column>
              ))}
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default FeatureGrid;
