// @flow

import * as React from 'react';
// import type { PageContentBase } from '../../types';
import { Grid, Container } from 'semantic-ui-react';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  // row: {
  //   ...PageContentBase,
  //   content: string
  // }
  row: Object,
  extraClass?: string
};

const IntroCopy = ({ row, extraClass }: Props) => {
  return (
    <div className={`page-row  ${extraClass ? extraClass : ''}`}>
      <Container>
        <Grid centered stackable>
          <Grid.Column tablet={10} computer={8}>
            {ReactHtmlParser(row.content || '')}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default IntroCopy;
