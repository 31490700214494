// @flow
export const premiumLink = (): string => {
    const env = process.env.REACT_APP_RM_ENV;
    switch (env) {
        case 'prod':
        case 'production':
            return 'https://premium.roymorgan.com';
        case 'uat':
            return 'https://premium.roymorganuat.com';
        case 'dev':
        default:
            return 'https://premium.roymorgandev.com';
    }
};
