// @flow
import { REQUEST_PERSONAS, RECEIVE_PERSONAS } from '../actions/personas';
import type {
  PageState as State,
  RequestPersonasAction,
  RecievePersonasAction
} from '../types';
import { createSelector } from 'reselect';

type Action = RequestPersonasAction | RecievePersonasAction;

const initialState = {
  isFetching: false
};

export function personas(state: State = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_PERSONAS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_PERSONAS:
      return {
        ...state,
        isFetching: false,
        byId: [...action.personas]
      };
    default:
      return state;
  }
}

export const getPersonasSortedAlphabetically = createSelector(
  state => state.personas.byId,
  items => {
    if (items === undefined) {
      return [];
    }
    return Object.keys(items)
      .sort(function(a, b) {
        // return items[ a ].name - items[ b ].name;
        if (
          items[a].postTitle === undefined ||
          items[b].postTitle === undefined
        ) {
          return 0;
        }
        if (
          items[a].postTitle.toLowerCase() < items[b].postTitle.toLowerCase()
        ) {
          return -1;
        } else if (
          items[a].postTitle.toLowerCase() > items[b].postTitle.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(function(sortedKey) {
        return items[sortedKey];
      });
  }
);
