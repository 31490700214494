// @flow

import * as React from 'react';
import { Grid, Container } from 'semantic-ui-react';

type Props = {
  row: any
};

class ContactOptions extends React.Component<Props> {
  render() {
    return (
      <div className="page-row contact-options">
        <Container>
          <Grid stackable>
            <Grid.Column tablet={6} computer={6} largeScreen={3}>
              <div className="callout">
                <div className="callout__icon callout__icon--sales" />
                <h3 className="text--cyan">Sales</h3>
                <p className="contact-options__link">
                  <a href="tel:1800 633 813">1800 633 813</a>
                </p>
              </div>
            </Grid.Column>
            <Grid.Column tablet={6} computer={6} largeScreen={3}>
              <div className="callout">
                <div className="callout__icon callout__icon--support" />
                <h3 className="text--cyan">Support</h3>
                <p className="contact-options__link">
                  <a href="mailto:askroymorgan@roymorgan">
                    askroymorgan@roymorgan.com
                  </a>
                </p>
              </div>
            </Grid.Column>
            <Grid.Column tablet={6} computer={6} largeScreen={3}>
              <div className="callout">
                <div className="callout__icon callout__icon--general-enquiries" />
                <h3 className="text--cyan">General Enquiries</h3>
                <p className="contact-options__link">
                  <a href="mailto:askroymorgan@roymorgan.com">
                    askroymorgan@roymorgan.com
                  </a>
                </p>
              </div>
            </Grid.Column>
            <Grid.Column tablet={6} computer={6} largeScreen={3}>
              <div className="callout">
                <div className="callout__icon callout__icon--partner-enquiries" />
                <h3 className="text--cyan">Partner Enquiries</h3>
                <p className="contact-options__link">
                  <a href="mailto:live@roymorgan.com">live@roymorgan.com</a>
                </p>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default ContactOptions;
