// @flow

export const productById = {
  '1': 'Live Management',
  '2': 'Audiences',
  '3': 'Search',
  '4': 'Live Mapping',
  '5': 'Helix Encoding',
  '6': 'Helix 2.0',
};

export const idByProduct = {
  'Live Management': 1,
  Audiences: 2,
  Search: 3,
  'Live Mapping': 4,
  'Helix Encoding': 5,
  'Helix 2.0': 6,
};

export const isNZHelix = (): boolean =>
  process.env.REACT_APP_DATA_ENDPOINT === 'nz-helix' ||
    window.location.host.includes('nz')
    ? true
    : false;

export const isIndoHelix = (): boolean =>
  process.env.REACT_APP_DATA_ENDPOINT === 'indo-helix' ||
    window.location.host.includes('id')
    ? true
    : false;

export const getHelixBaseUrl = () => {
  if (isNZHelix()) {
    return 'nzhelix.roymorgan.com';
  }
  else if (isIndoHelix()) {
    return 'idhelix.roymorgan.com';
  }
  return 'helix.roymorgan.com';
};
