// @flow strict
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Grid, Container, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from '../Link';
import { fetchProducts } from '../../actions';
import { findById } from '../../helpers';
import type { Product } from '../../types';

type Props = {
  row: {
    acfFcLayout: string,
    product1: Product | boolean,
    product2: Product | boolean,
    product3: Product | boolean
  },
  products: Product[],
  isFetching: boolean,
  fetchProducts: () => Promise<void>
};

const ProductSingle = ({ product }: { product: Product }) => (
  <Grid.Column tablet={6} computer={4}>
    <div className="product-col">
      <div className="text--center">
        <Link url={`/products/${product.slug}`}>
          <div
            className="callout__icon callout__icon--large"
            style={{
              backgroundImage: `url(${
                product.acf.icon ? product.acf.icon.url : ''
              })`
            }}
          />
        </Link>
      </div>
      <Link
        url={`/products/${product.slug}`}
        className="product-col__title h2--tile"
      >
        <h2>{ReactHtmlParser(product.title ? product.title.rendered : '')}</h2>
      </Link>
      <p className="product-col__strapline">
        {ReactHtmlParser(product.acf ? product.acf.strapline : '')}
      </p>
      <div className="product-col__description">
        {ReactHtmlParser(product.excerpt ? product.excerpt.rendered : '')}
      </div>

      <div className="product-col__product-button-container">
        <Button
          className="button--icon-triangle"
          as={Link}
          url={`/products/${product.slug}`}
        >
          View Product
        </Button>
      </div>
    </div>
  </Grid.Column>
);

class ProductRow extends React.Component<Props> {
  componentDidMount() {
    const { fetchProducts, products, isFetching } = this.props;

    if (!products || (!products && isFetching === false)) {
      fetchProducts();
    }
  }

  render() {
    const { products, row } = this.props;

    let currentProduct1: ?Product;
    let currentProduct2: ?Product;
    let currentProduct3: ?Product;

    if (row.product1 && typeof row.product1.id === 'number' && products) {
      currentProduct1 = findById(products, row.product1.id);
    }
    if (row.product2 && typeof row.product2.id === 'number' && products) {
      currentProduct2 = findById(products, row.product2.id);
    }
    if (row.product3 && typeof row.product3.id === 'number' && products) {
      currentProduct3 = findById(products, row.product3.id);
    }

    return (
      <Container>
        <Grid stackable stretched>
          {currentProduct1 && <ProductSingle product={currentProduct1} />}
          {currentProduct2 && <ProductSingle product={currentProduct2} />}
          {currentProduct3 && <ProductSingle product={currentProduct3} />}
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products.items,
    isFetching: state.products.isFetching
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      fetchProducts
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRow);
