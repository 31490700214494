// @flow
import * as React from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ReactHtmlParser from 'html-react-parser';
import Link from './Link';
import SemanticReduxFormField from './SemanticReduxFormField';
import { required, phoneNumber } from '../helpers';
import type { Link as LinkType } from '../types';
import countries from 'country-list';


type Props = {
  report?: boolean,
  reportUrl?: string,
  successMessage: string,
  successLink: {},
  reportSuccessMessage: string,
  submitted: boolean,
  successMessage?: ?string,
  successLink?: ?LinkType,
  reportSuccessMessage?: ?string,
  onSubmit: (v: Object) => void,
  handleSubmit: (f: Function) => void
};

let ContactForm = (props: Props) => {
  const countryOptions = [];
  const countryList = countries.getCodeList();
  Object.keys(countryList).forEach(key => {
    countryOptions.push({
      key,
      value: countryList[key],
      text: countryList[key]
    });
  });

  const {
    report,
    successMessage,
    successLink,
    onSubmit,
    handleSubmit,
    submitted,
    reportSuccessMessage
  } = props;

  return (
    <div>
      {!submitted && (
        <Form onSubmit={handleSubmit(onSubmit)} className="contact-form__form">
          <Grid centered stackable>
            <Grid.Column tablet={6} computer={3}>
              <Field
                name="firstName"
                component={SemanticReduxFormField}
                as={Form.Input}
                label="First Name"
                required
                validate={required}
                maxLength="20"
              />
            </Grid.Column>
            <Grid.Column tablet={6} computer={3}>
              <Field
                name="lastName"
                component={SemanticReduxFormField}
                as={Form.Input}
                label="Last Name"
                required
                validate={required}
                maxLength="20"
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Field
                name="email"
                component={SemanticReduxFormField}
                as={Form.Input}
                label="Email"
                required
                type="email"
                validate={required}
                maxLength="60"
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Field
                name="phone"
                component={SemanticReduxFormField}
                as={Form.Input}
                label="Phone"
                required
                type="tel"
                validate={required}
                normalize={phoneNumber}
                maxLength="20"
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Field
                name="country"
                component={SemanticReduxFormField}
                as={Form.Select}
                label="Country"
                placeholder="Please select"
                options={countryOptions}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Field
                name="company"
                component={SemanticReduxFormField}
                as={Form.Input}
                label="Company"
                required
                validate={required}
                maxLength="45"
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Field
                name="jobTitle"
                component={SemanticReduxFormField}
                as={Form.Input}
                label="Job Title"
                required
                validate={required}
                maxLength="45"
              />
            </Grid.Column>
            {!props.report && (
              <Grid.Column width={12}>
                <Field
                  name="message"
                  component={SemanticReduxFormField}
                  as={Form.TextArea}
                  label="Message"
                  maxLength={1500}
                />
              </Grid.Column>
            )}
            <Grid.Column width={12}>
              <Field
                name="insightsAgreement"
                component={SemanticReduxFormField}
                as={Form.Checkbox}
                label="I would like to receive occasional insights from Roy Morgan Live"
              />
            </Grid.Column>
            <Grid.Column width={12} textAlign="center">
              {report ? (
                <Button className="button--left-icon-download">
                  Download Report
                </Button>
              ) : (
                <Button className="contact-form__submit">Send Message</Button>
              )}
            </Grid.Column>
          </Grid>
        </Form>
      )}
      {submitted &&
        !report && (
          <div className="page-row ">
            <div className="body--lead contact-form__success-message">
              {ReactHtmlParser(successMessage || '')}
            </div>
            <div className="text--center">
              <Button
                inverted
                as={Link}
                url={successLink && successLink.url ? successLink.url : ''}
                target={
                  successLink && successLink.target
                    ? successLink.target
                    : undefined
                }
              >
                {successLink && successLink.title
                  ? successLink && successLink.title
                  : ''}
              </Button>
            </div>
          </div>
        )}
      {submitted &&
        report && (
          <div className="page-row ">
            {ReactHtmlParser(reportSuccessMessage || '')}
          </div>
        )}
    </div>
  );
};

ContactForm = reduxForm({
  form: 'contact'
})(ContactForm);

// $FlowFixMe
ContactForm = connect(state => ({
  initialValues: {
    country: 'Australia',
    insightsAgreement: false
  }
}))(ContactForm);

export default ContactForm;
