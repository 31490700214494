// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Head from '../components/Head';
import { fetchProducts } from '../actions';
import { findBySlug, isNZHelix, isIndoHelix } from '../helpers';
import PageLoader from '../components/PageLoader';
import PageContent from '../components/PageContent';
import type { Products, Product as ProductType } from '../types';
import { Fragment } from 'react/cjs/react.production.min';

type Props = {
  products: Products,
  match: {
    params: {
      persona_slug: string,
    },
  },
  fetchProducts: () => Promise<void>,
};

class Home extends React.Component<Props> {
  componentDidMount() {
    const { fetchProducts } = this.props;
    fetchProducts();
  }

  render() {
    const { products, match } = this.props;
    const slug = isNZHelix()
      ? 'helix-personas-nz'
      : isIndoHelix()
      ? 'helix-personas-id'
      : 'helix-personas';

    const persona_slug = match && match.params.persona_slug;
    let currentProduct: ?ProductType;
    if (products && products.items) {
      currentProduct = (findBySlug(products.items, slug): ProductType);
    }
    if (!currentProduct) {
      return <PageLoader />;
    }

    let currentProductMeta;
    // Yoast doesn't work for custom taxonomies. Using custom fields.
    if (currentProduct) {
      const metaTitle: string =
        currentProduct.acf && currentProduct.acf.customSeoTitle
          ? currentProduct.acf.customSeoTitle
          : currentProduct.title.rendered + ' | Roy Morgan Live';

      const metaDesc: ?string =
        currentProduct.acf && currentProduct.acf.customSeoDescription
          ? currentProduct.acf.customSeoDescription
          : undefined;

      currentProductMeta = {
        title: metaTitle,
        metaDesc,
      };
    }

    return (
      <Fragment>
        <div>
          {currentProduct && <Head metaTags={currentProductMeta} />}
          <PageContent
            pageContent={
              currentProduct.acf ? currentProduct.acf.pageContent : undefined
            }
            persona_slug={persona_slug}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      fetchProducts,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
