// @flow strict
import * as React from 'react';
import { Container, Grid, Button } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactHtmlParser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { fetchProducts } from '../../actions';
import { findById } from '../../helpers';
import type { SuiteProductRow as ContentType, Product } from '../../types';

type Props = {
  row: ContentType,
  products: Array<Product>
};

class SuiteProductRow extends React.Component<Props> {
  // componentDidMount() {
  //   const { products, row } = this.props;
  //   let missingProduct = false;

  //   console.log(row);

  //   // Detect if the relevant products are in state. If not, fetch all products.
  //   // This isn't working

  //   for (let i = 0, len = row.suiteProductRepeater.length; i < len; i++) {

  //     console.log(products, row.suiteProductRepeater[i].selectedProduct);

  //     if (findById(products, row.suiteProductRepeater[i].selectedProduct) === false) {
  //       missingProduct = true;
  //       console.log(row.suiteProductRepeater[i], "missing");
  //     } else {
  //       console.log(row.suiteProductRepeater[i], "present");
  //     }
  //   }

  //   if (missingProduct) {
  //     fetchProducts();
  //     console.log("fetching products");
  //   }
  // }

  render() {
    const { row, products } = this.props;
    const { suiteProductRepeater } = row;

    let tabletWidth;
    let computerWidth;

    switch (suiteProductRepeater.length) {
      case 1:
        tabletWidth = 8;
        computerWidth = 8;
        break;
      case 2:
        tabletWidth = 6;
        computerWidth = 6;
        break;
      default:
        tabletWidth = 6;
        computerWidth = 4;
    }

    return (
      <div className="margin--bottom-40">
        {row.title && (
          <h4 className="margin--bottom-20">{ReactHtmlParser(row.title)}</h4>
        )}
        <Container>
          <Grid stackable centered stretched>
            {suiteProductRepeater.map(function(productItem, i) {
              const { selectedProduct, customProductText } = productItem;
              const product = findById(products, Number(selectedProduct));
              let displayTitle;

              if (!product) {
                return <div key={`industry-callout-${i}`} />;
              }

              if (product.acf && product.acf.optionalSuiteDisplayTitle) {
                displayTitle = product.acf.optionalSuiteDisplayTitle;
              } else {
                displayTitle =
                  product.title && product.title.rendered
                    ? product.title.rendered
                    : '';
              }

              return (
                <Grid.Column
                  tablet={tabletWidth}
                  computer={computerWidth}
                  key={`industry-callout-${i}`}
                >
                  <div className="callout callout">
                    <Link to={`/products/${product.slug}`}>
                      <div
                        className="callout__icon callout__icon--large"
                        style={{
                          backgroundImage: `url(${
                            product.acf &&
                            product.acf.icon &&
                            product.acf.icon.url
                              ? product.acf.icon.url
                              : ''
                          })`
                        }}
                      />
                    </Link>
                    <div className="callout__title">
                      <Link
                        to={`/products/${product.slug}`}
                        className="text--cyan"
                      >
                        <h2 className="h2--tile">
                          {ReactHtmlParser(displayTitle)}
                        </h2>
                      </Link>
                    </div>
                    <div className="callout__content">
                      <div className="text--center">
                        {customProductText
                          ? ReactHtmlParser(customProductText)
                          : ReactHtmlParser(
                              product.acf && product.acf.strapline
                                ? product.acf.strapline
                                : ''
                            )}
                      </div>
                    </div>
                    <Button
                      as={Link}
                      to={`/products/${product.slug}`}
                      className="button--icon-triangle"
                    >
                      View Product
                    </Button>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  products: state.products.items,
  isFetching: state.products.isFetching
});
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      fetchProducts
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SuiteProductRow);
