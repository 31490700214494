function makeURLFriendly(title) {
    return title
      .toLowerCase()
      .replace(' - ', '-')
      .replace(' ', '-')
      .replace(' ', '-')
      .replace(',', '')
      .replace(' ', '-');
  }
  
export const makeCommunityURL = (slug, title) => {
    return slug + '-' + makeURLFriendly(title);
  }

export const makePersonaURL = (postName) => {
    return makeURLFriendly(postName);
  }
  