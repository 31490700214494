// @flow
import * as React from 'react';
import SVG from 'react-inlinesvg';

type Props = {
  src?: string,
  colour?: string,
  alt?: string,
  title?: string,
  fill?: ?string,
  stroke?: ?string,
  width?: string,
  height?: string,
};

const LoadSVG = ({
  src,
  colour,
  alt,
  title,
  fill = colour,
  stroke = colour,
  width = '22px',
  height = width,
}: Props): null | React.Element<'img'> | React.Element<'span'> => {
  if (!src) {
    return null;
  }
  const extension = src.split('.').pop();

  switch (extension) {
    case 'svg':
      return (
        <span style={{ fill, stroke, height, width }}>
          <SVG src={src} aria-label={alt} fill={colour} />
        </span>
      );

    case 'png':
    case 'jpg':
    case 'jpeg':
      return <img src={src} alt={alt} title={title} />;
    default:
      return null;
  }
};

export default LoadSVG;
