// @flow strict
import React from 'react';
import type { VideoAndContentType } from '../../types';

type Props = {
  row: VideoAndContentType,
};

const VideoAndContent = ({ row }: Props) => {
  return (
    <div className="video-and-text__wrapper">
      <div className="video-and-text__container">
        {row?.title && <h2 className="video-and-text__title">{row?.title}</h2>}
        <div className="video-and-text__grid">
          <div className="video-and-text__video">
            <iframe
              width="853"
              height="480"
              src={row?.videoUrl}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <div className="video-and-text__content">
            <h3>{row?.sideHeading}</h3>
            <p>{row?.sideText}</p>
            {row?.sideDownloadRepeater &&
              row?.sideDownloadRepeater.map((downloadLink, i) => {
                return (
                  <a key={i} href={downloadLink.downloadTarget}>
                    {downloadLink.downloadText}
                  </a>
                );
              })}
          </div>
        </div>

        <div className="video-and-text__bottom-text--wrapper">
          {row?.paragraphRepeater &&
            row?.paragraphRepeater.map((paragraph, i) => {
              return (
                <div
                  className={'video-and-text__bottom-text--container'}
                  key={i}
                >
                  <h3 className="video-and-text__bottom-text--heading">
                    {paragraph?.paragraphHeading}
                  </h3>
                  <p>{paragraph?.paragraphText}</p>
                </div>
              );
            })}
          {row?.buttonText && row?.buttonUrl && (
            <div className="video-and-text__button-wrapper">
              <a className="video-and-text__button" href={row?.buttonUrl}>
                {row?.buttonText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoAndContent;
