// @flow
import * as React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Grid, Container, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from '../Link';
import InlineSvg from '../InlineSvg';
import PageLoader from '../PageLoader';
import { findById } from '../../helpers';
import { fetchProducts, fetchSuites } from '../../actions';
import type { Suite, Product, ProductOrder } from '../../types';

type Props = {
  row: {
    acfFcLayout: string,
    suite: number
  },
  suites: Array<Suite>,
  products?: Array<Product>,
  isFetching: boolean,
  fetchProducts: () => Promise<void>,
  fetchSuites: () => Promise<void>
};

class SuiteRow extends React.Component<Props> {
  componentDidMount() {
    const { fetchProducts, fetchSuites, products, suites } = this.props;

    if (!products) {
      fetchProducts();
    }
    if (!suites) {
      fetchSuites();
    }
  }

  render() {
    const { row, suites, products } = this.props;
    const suiteId = row.suite;

    if (!suiteId) {
      return <strong>Error: No suite selected</strong>;
    }

    let currentSuite;
    let productOrder: ?Array<ProductOrder>;

    if (suites) {
      currentSuite = (findById(suites, suiteId): Suite);
      if (currentSuite && currentSuite.acf) {
        productOrder = currentSuite.acf.productOrder;
      }
    }

    if (!currentSuite) {
      return <PageLoader />;
    }

    let suiteProducts: Array<Product> = [];

    // Extract current suite's product information from the top-level 'products' object in the redux store
    if (productOrder && products) {
      for (let i = 0, len = productOrder.length; i < len; i++) {
        suiteProducts.push(
          findById(products, Number(productOrder[i].productSelect))
        );
      }
    }

    return (
      <Container>
        <Grid>
          <Grid.Column>
            <div className="suite-col">
              <div className="text--center">
                <Link
                  url={`/suites/${currentSuite.slug}`}
                  className={`callout__icon callout__icon--large callout__icon--${
                    currentSuite.slug
                  }`}
                  style={{
                    backgroundImage: `url(${
                      currentSuite.acf && currentSuite.acf.icon
                        ? currentSuite.acf.icon.url
                        : ''
                    })`
                  }}
                />
              </div>
              <Link
                className="product-col__title"
                url={`/suites/${currentSuite.slug}`}
              >
                <h2>{ReactHtmlParser(currentSuite.name)}</h2>
              </Link>
              <p className="product-col__strapline">
                {ReactHtmlParser(currentSuite.acf.strapline || '')}
              </p>
              {currentSuite.description && (
                <p className="product-col__suite-description">
                  {ReactHtmlParser(currentSuite.description)}
                </p>
              )}
              <div className="product-col__grid">
                <Grid stackable centered stretched>
                  {suiteProducts.map(function(product, i) {
                    if (!product) {
                      return undefined;
                    }
                    return (
                      <Grid.Column
                        tablet={6}
                        computer={4}
                        textAlign="center"
                        key={i}
                      >
                        <div className="product-col__grid-inner">
                          <Link
                            url={`/products/${product.slug}`}
                            className="product-col__grid-title"
                          >
                            <h4>{product.title && product.title.rendered}</h4>
                          </Link>
                          <p className="product-col__grid-text">
                            {product.acf && product.acf.strapline &&
                              ReactHtmlParser(product.acf.strapline)}
                          </p>
                          <div className="product-col__grid-button-container">
                            <Button
                              as={Link}
                              url={`/products/${product.slug}`}
                              className="product-col__grid-button"
                              inverted
                            >
                              View Product <InlineSvg name="triangle-right" />
                            </Button>
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  })}
                </Grid>
              </div>
              <div className="text--center">
                <Button
                  as={Link}
                  className="button--icon-triangle"
                  url={`/suites/${currentSuite.slug}`}
                >
                  View Suite
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    suites: state.suites.items,
    isFetching: state.suites.isFetching,
    products: state.products.items
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      fetchSuites,
      fetchProducts
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuiteRow);
