// @flow

import React from 'react';
import { Grid } from 'semantic-ui-react';
import Link from './Link';

type Props = {
  megaMenuOpen: boolean,
  onNavigate: () => any,
  singleLinks: Array<Object>
};

class ProductsMegaMenu extends React.Component<Props> {
  render() {
    return (
      <div
        className={`nav-mega-menu ${
          this.props.megaMenuOpen ? 'nav-mega-menu--open' : ''
        }`}
      >
        <div className="nav-mega-menu__contents">
          <Grid columns={2} className="nav-mega-menu__single-products">
            {this.props.singleLinks.map((link, i) => (
              <Grid.Column
                key={i}
                as={Link}
                url={link.url}
                className="nav-mega-menu__item"
              >
                <div className="nav-mega-menu__link">{link.title}</div>
                <p>{link.strapline}</p>
              </Grid.Column>
            ))}
          </Grid>
          <div className="nav-mega-menu__suites">
            <div className="nav-mega-menu__item">
              <Link
                url="https://live.roymorgan.com/suites/live-audiences"
                className="nav-mega-menu__suite-link"
              >
                <span className="nav-mega-menu__link">Live Audiences</span>
                <p className="nav-mega-menu__suite-strapline">
                  Search, activate and evaluate audiences in real-time
                </p>
              </Link>
              <p>
                Includes:
                <br />
                <Link
                  url="https://live.roymorgan.com/products/live-audience-search"
                  className="nav-mega-menu__link nav-mega-menu__link--hover-colour"
                >
                  Search
                </Link>
                <br />
                <Link
                  url="https://live.roymorgan.com/products/live-audience-activation"
                  className="nav-mega-menu__link nav-mega-menu__link--hover-colour"
                >
                  Activation
                </Link>
                <br />
                <Link
                  url="https://live.roymorgan.com/products/live-audience-evaluation"
                  className="nav-mega-menu__link nav-mega-menu__link--hover-colour"
                >
                  Evaluation
                </Link>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsMegaMenu;
