// @flow
import * as React from 'react';
import { Input } from 'semantic-ui-react';

type Props = {
  as?: React.Element<any>,
  input: Object,
  label?: string,
  meta: {
    touched: boolean,
    error: ?string,
    warning: ?string
  }
};

const SemanticReduxFormField = ({
  input,
  meta: { touched, error, warning },
  as: As = Input,
  ...props
}: Props) => {
  const handleChange = (e, { value }) => {
    return input.onChange(value);
  };
  return (
    <div>
      {/* $FlowFixMe */}
      <As
        {...input}
        value={input.value}
        {...props}
        onChange={handleChange}
        error={touched && error}
      />
      {touched && (warning && <span>{warning}</span>)}
    </div>
  );
};

export default SemanticReduxFormField;
