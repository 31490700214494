// @flow strict
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { personas } from './personas';
import products from './products';
import { marketingPages } from './marketingPages';
import { settings } from './settings';

const rootReducer = combineReducers({
  personas,
  settings,
  products,
  marketingPages,
  form: formReducer
});

export default rootReducer;
