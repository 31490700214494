import 'react-app-polyfill/ie11'; // For IE 11 support
import * as React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import 'roy-morgan-base-theme/dist/semantic.min.css';
import './assets/css/index.css';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// Create Redux store with initial state
// $FlowFixMe
const store = configureStore(preloadedState || {});

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});

const rootElement = document.getElementById('root');

// $FlowFixMe
if (rootElement.hasChildNodes()) {
  hydrate(<Root store={store} />, rootElement);
} else {
  render(<Root store={store} />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
