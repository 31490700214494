// @flow

import * as React from 'react';
import { Loader } from 'semantic-ui-react';

const PageLoader = () => (
  <div className="page-loader">
    <Loader active inline="centered" />
  </div>
);

export default PageLoader;
