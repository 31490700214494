// @flow
import * as React from 'react';
import CommunitySummaryTile from '../community/CommunitySummaryTile';
import Slider from 'react-slick';
import type { WelcomePage } from '../../types';
import ReactHtmlParser from 'html-react-parser';
import { makePersonaURL } from '../../helpers/makeURL';
import classNames from 'classnames';

const PersonaCarousel = ({
  fullWidth,
  products,
  welcomePage: {
    acf: {
      // $FlowFixMe
      browseCommunitySubtitle,
      // $FlowFixMe
      browseCommunityText,
    },
  },
  tall,
}: {
  products: Object,
  fullWidth?: boolean,
  welcomePage: WelcomePage,
  tall?: boolean,
}): React.Element<'div'> => {
  const settings = {
    arrows: false,
    className: 'slider-custom',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const [sortPersonas, setSortPersonas] = React.useState();

  React.useEffect(() => {
    if (products) {
      const sortedArray = products.sort(
        (a, b) =>
          parseInt(a.acf.personacommunityNumber) -
          parseInt(b.acf.personacommunityNumber)
      );
      setSortPersonas(sortedArray);
    }
  }, [products]);

  return (
    <div
      className={classNames('community-carousel', {
        'community-carousel--full-width': fullWidth,
        'community-carousel--tall': tall,
      })}
    >
      <div className="community-carousel__wrapper">
        {browseCommunitySubtitle && browseCommunityText && (
          <div className="community-carousel__head">
            <h2>{browseCommunitySubtitle}</h2>
            {ReactHtmlParser(browseCommunityText)}
          </div>
        )}
        <div className="community-carousel__items-wrapper">
          <Slider {...settings}>
            {sortPersonas?.map((community, i) => (
              <CommunitySummaryTile
                key={i}
                id={community?.postName}
                name={community?.acf?.title}
                linkText={'BROWSE'}
                colour={community?.acf?.color}
                image={community?.acf?.featuredImage?.url}
                title={community.id + ' - ' + community?.acf?.title}
                url={makePersonaURL(community?.postTitle)}
                personaCarousel={true}
                postName={community?.postName}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PersonaCarousel;
