// @flow
import * as React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import SemanticReduxFormField from './SemanticReduxFormField';
import { required, phoneNumber } from '../helpers';
import type { InlineCallbackForm as ContentType } from '../types';

type Props = {
  success: boolean,
  error: boolean,
  submitted: boolean,
  onSubmit: () => void,
  handleSubmit: (f: Function) => void,
  row: ContentType
};

class InlineCallbackForm extends React.Component<Props> {
  render() {
    const { onSubmit, success, error, submitted, handleSubmit } = this.props;

    return (
      <div className="inline-callback-form__form">
        {!submitted || (submitted && error) ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="inline-callback-form__form-group">
                <div className="inline-callback-form__form-item">
                  <label htmlFor="name">Name *</label>
                  <Field
                    name="name"
                    component={SemanticReduxFormField}
                    as={Form.Input}
                    required
                    validate={required}
                    maxLength={60}
                    placeholder="Enter Full Name"
                  />
                </div>

                <div className="inline-callback-form__form-item">
                  <label htmlFor="phone">Phone *</label>
                  <Field
                    name="phone"
                    component={SemanticReduxFormField}
                    as={Form.Input}
                    type="tel"
                    required
                    validate={required}
                    normalize={phoneNumber}
                    placeholder="Eg. 0400 000 000"
                    maxLength={20}
                  />
                </div>
              </div>
              <div className="inline-callback-form__form-group">
                <div className="inline-callback-form__form-item">
                  <label htmlFor="email">Email *</label>
                  <Field
                    name="email"
                    component={SemanticReduxFormField}
                    as={Form.Input}
                    type="email"
                    placeholder="youremail@email.com.au"
                    required
                    validate={required}
                  />
                </div>
                <div className="inline-callback-form__form-item">
                  <label htmlFor="time">Best Time To Call *</label>
                  <Field
                    name="time"
                    component="select"
                    label="Best Time To Call"
                    type="select"
                    required
                    validate={required}
                  >
                    <option className='placeholder-option' disabled></option>
                    {this.props.row?.timeOptionsRepeater && this.props.row?.timeOptionsRepeater.map((timeSlot, i) => {
                      return <option key={i} value={timeSlot.callTimeOption}>{timeSlot.callTimeOption}</option>
                    })}
                  </Field>
                </div>
              </div>

              <Button className="inline-callback-form__submit footer_callback">
                Request a Callback
              </Button>

              {error && (
                <div className="form__response">
                  There was an error with your request. Please try again.
                </div>
              )}
            </div>
          </Form>
        ) : null}
        {submitted && success && ( 
          <div className="form__response form__response--success">
            Thank you, a member of our team will be in touch with you soon
          </div>
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: 'inline-callback',
})(InlineCallbackForm);
