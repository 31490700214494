// @flow strict
import React from 'react';
import InlineSVG from '../InlineSvg';
import type { FeaturedResourcesType } from '../../types';
import { liveLink } from '../../helpers/liveLink';

type Props = {
  row: FeaturedResourcesType,
};

const FeaturedResources = ({ row }: Props) => {
  return (
    <div className="featured-resources__wrapper">
      <div className="featured-resources__container">
        <h2 className="featured-resources__title">{row?.title}</h2>
        <div className="featured-resources__grid">
          {row?.featuredResourcesRepeater &&
            row?.featuredResourcesRepeater?.map((resourceContent, i) => {
                return (
                <div key={i} className="featured-resources__content--container">
                  <div className="featured-resources__content-group">
                    <div className="featured-resources__container--header">
                      <h5>{resourceContent?.subTitle}</h5>
                      <img
                        className="featured-resources__container--image"
                        src={resourceContent?.logo}
                        alt=""
                      />
                    </div>
                    <div className="featured-resources__container--heading">
                      <h3>{resourceContent?.resource?.postTitle}</h3>
                    </div>
                    <div className="featured-resources__container--text">
                      <p>{resourceContent?.articleExcerpt}</p>
                    </div>
                  </div>
                  <a
                    href={liveLink() + '/resources/' + resourceContent?.resource?.postName}
                    className="featured-resources__container--hyperlink"
                  >
                    Read More
                    <InlineSVG name="arrow-right" colour="currentColor" />
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedResources;
