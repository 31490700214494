// @flow strict
import React from 'react';
import { getStaticPersonas } from '../helpers/personas';
import CommunitySummaryTile from './CommunitySummaryTile';
import Link from './Link';
import { Grid } from 'semantic-ui-react';
import type { CommunitySummariesType } from '../types';

type Props = {
  row: CommunitySummariesType,
};

const CommunitySummaries = ({ row }: Props) => {
  // fetch hardcoded community data
  const communities = getStaticPersonas();

  const communityItems = communities
    ? communities.map((community, key) => (
        <CommunitySummaryTile
          key={key}
          id={community.id}
          name={community.name}
          colour={community.colour}
          image={community.img}
          title={community.id + ' - ' + community.name}
          summary={community.summary}
          url={`/${community.id}/`}
        />
      ))
    : [];

  return (
    <div className="community-summaries">
      <div
        className={`community-summaries__grid ${
          communities.length === 7 ? 'community-tiles-flex--nz' : ''
        }`}
      >
        <div className="community-summaries__main">
          <h2>{row?.title}</h2>
          <p>{row?.intro}</p>
          <div className="community-summaries__button-grid">
            {row?.buttonRepeater &&
              row?.buttonRepeater.map((button, i) => {
                return (
                  <Grid.Column
                    as={Link}
                    url={button?.button?.url}
                    target={button?.button?.target}
                    key={i}
                    className="button"
                  >
                    {button?.button?.title}
                  </Grid.Column>
                );
              })}
          </div>
        </div>
        {communityItems}
      </div>
    </div>
  );
};

export default CommunitySummaries;
