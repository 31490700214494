// @flow
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import * as T from '../types';
import { getLiveCMSBaseURL } from '../helpers';

export const REQUEST_PERSONAS = 'REQUEST_PERSONAS';
function requestPersonas() {
  return {
    type: REQUEST_PERSONAS
  };
}

export const RECEIVE_PERSONAS = 'RECEIVE_PERSONAS';
function receivePersonas(json: Array<T.Product>) {
  return {
    type: RECEIVE_PERSONAS,
    personas: json
  };
}

export function fetchPersonas(): T.ThunkAction {
  return function(dispatch): T.Dispatch {
    dispatch(requestPersonas());

    const baseUrl = getLiveCMSBaseURL();

    return fetch(`${baseUrl}/persona-summary`)
      .then(response => (response.json(): Promise<Object>))
      .then((json: T.PersonasResponse) => {
        const camelizedJson = camelizeKeys(json /*.personas*/, noCamelize);
        return dispatch(receivePersonas(camelizedJson));
      })
      .catch((error: Error) => console.error('An error occured.', error));
  };
}
