// @flow

import * as React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import type { FeatureScreenshots as ContentType } from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: ContentType
};

class FeatureScreenshots extends React.Component<Props> {
  render() {
    const { row } = this.props;
    const { features } = row;

    return (
      <div className="page-row feature-screenshots">
        <Container>
          <Grid stackable centered verticalAlign="middle">
            {features.map((feature, i) => {
              return (
                <Grid.Row
                  key={`feature-${i}`}
                  className={` feature-screenshots__row ${
                    i % 2 !== 0 ? 'reversed' : ''
                  }`}
                >
                  <Grid.Column
                    computer={5}
                    tablet={6}
                    className="feature-screenshots__image-column"
                  >
                    {feature.screenshot &&
                      feature.screenshot.url && (
                        <img
                          src={feature.screenshot.url}
                          alt={feature.screenshot.alt}
                        />
                      )}
                  </Grid.Column>
                  <Grid.Column
                    computer={7}
                    tablet={6}
                    className="feature-screenshots__text-column"
                  >
                    <div className="feature-screenshots__text-container">
                      {feature.title && (
                        <h3 className="text--cyan">
                          {ReactHtmlParser(feature.title)}
                        </h3>
                      )}
                      {feature.description && (
                        <div className="body--lead">
                          {ReactHtmlParser(feature.description)}
                        </div>
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
}

export default FeatureScreenshots;
