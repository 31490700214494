// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLoader from '../../components/PageLoader';
import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';
import type { Products } from '../../types';
import { loadMarketingPage } from '../../actions/readMarketingPage';
import { isNZHelix, isIndoHelix } from '../../helpers';

type Props = {
  products: Products,
  match: {
    params: {
      persona_slug: string
    }
  },
  loadMarketingPage: (slug: string) => Promise<void>,
  isFetchingMarketingPage: boolean,
  marketingPage: Object
};

class LandingPageThree extends React.Component<Props> {
  componentDidMount() {
    if (isNZHelix()) {
      this.props.loadMarketingPage('nz-landing-page-three');
    }
    else if (isIndoHelix()) {
      this.props.loadMarketingPage('id-landing-page-three');
    }
    else {
      this.props.loadMarketingPage('landing-page-three');
    }
    window.scrollTo(0, 0);
  }

  render() {
    if (this.props.isFetchingMarketingPage || !this.props.marketingPage) {
      return <PageLoader />;
    }

    return (
      <div className="landing-page">
        <PageTitle
          title={
            this.props.marketingPage.title
              ? this.props.marketingPage.title.rendered
              : undefined
          }
        />
        {this.props.marketingPage.acf.pageContent && (
          <PageContent
            pageContent={
              // $FlowFixMe
              this.props.marketingPage.acf.pageContent
                ? this.props.marketingPage.acf.pageContent
                : undefined
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  if (isNZHelix()) {
    return {
      isFetchingMarketingPage: state.marketingPages.isFetching,
      marketingPage: state.marketingPages.pages['nz-landing-page-three']
        ? state.marketingPages.pages['nz-landing-page-three']
        : null
    };
  }
  else if (isIndoHelix()) {
    return {
      isFetchingMarketingPage: state.marketingPages.isFetching,
      marketingPage: state.marketingPages.pages['id-landing-page-three']
        ? state.marketingPages.pages['id-landing-page-three']
        : null
    };
  }
  else {
    return {
      isFetchingMarketingPage: state.marketingPages.isFetching,
      marketingPage: state.marketingPages.pages['landing-page-three']
        ? state.marketingPages.pages['landing-page-three']
        : null
    };
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      loadMarketingPage
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageThree);
