// @flow
import * as React from 'react';
import { getStaticPersonas } from '../../helpers/personas';
import CommunitySummaryTile from '../community/CommunitySummaryTile';
import Slider from 'react-slick';
import type { WelcomePage } from '../../types';
import { makeCommunityURL } from '../../helpers/makeURL';
import classNames from 'classnames';

const CommunityCarousel = ({
  fullWidth = false,
  welcomePage: {
    acf: {
      // $FlowFixMe
      browseCommunitySubtitle,
      // $FlowFixMe
      browseCommunityText,
    },
  },
  tall,
}: {
  fullWidth?: boolean,
  welcomePage: WelcomePage,
  tall?: boolean,
}): React.Element<'div'> => {
  const communities = getStaticPersonas();
  const settings = {
    arrows: false,
    className: classNames('slider-custom', {
      'slider-custom--extra-tall':
        browseCommunitySubtitle && browseCommunityText,
    }),
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div
      className={classNames('community-carousel', {
        'community-carousel--full-width': fullWidth,
        'community-carousel--tall': tall,
      })}
    >
      <div className="community-carousel__wrapper">
        {browseCommunitySubtitle && browseCommunityText && (
          <div className="community-carousel__head">
            <h2>{browseCommunitySubtitle}</h2>
            <p>{browseCommunityText}</p>
          </div>
        )}
        <div className="community-carousel__items-wrapper">
          <Slider {...settings}>
            {communities.map((community, i) => (
              <CommunitySummaryTile
                key={i}
                id={community.id}
                name={community.name}
                linkText={'BROWSE'}
                colour={community.colour}
                image={community.img}
                title={community.id + ' - ' + community.name}
                url={'/' + makeCommunityURL(community?.id, community?.name)}
                personaCarousel={false}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CommunityCarousel;
