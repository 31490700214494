// @flow strict
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContactFormRow from '../../components/PageContent/ContactFormRow';
import { sendContact } from '../../actions';

const mapStateToProps = state => {
  return {
    successMessage: state.settings.genericContactFormSuccessMessage,
    successLink: state.settings.genericContactFormSuccessLink
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      sendContact
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormRow);
