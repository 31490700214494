// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProducts } from '../actions';
import { isNZHelix, isIndoHelix } from '../helpers';
import { loadMarketingPage } from '../actions/readMarketingPage';
import type { Products } from '../types';
import { Fragment } from 'react/cjs/react.production.min';
import PageContent from '../components/PageContent';
import PageLoader from '../components/PageLoader';
import { getPersonasSortedAlphabetically } from '../reducers/personas';
import { fetchPersonas } from '../actions/personas';

type Props = {
  products: Products,
  match: {
    params: {
      persona_slug: string,
      community_slug: string,
    },
  },
  fetchPersonas: () => Promise<void>,
  fetchProducts: () => Promise<void>,
  loadMarketingPage: (slug: string) => Promise<void>,
  products: Array<Object>,
  isFetchingMarketingPage: boolean,
  isFetchingPersonas: boolean,
  marketingPage: Object,
};

class PersonaListing extends React.Component<Props> {
  componentDidMount() {
    const { fetchProducts } = this.props;
    fetchProducts();

    if (isNZHelix()) {
      this.props.loadMarketingPage('nz-persona-listing');
    } else if (isIndoHelix()) {
      this.props.loadMarketingPage('id-persona-listing');
    } else {
      this.props.loadMarketingPage('persona-listing');
    }

    window.scrollTo(0, 0);
  }

  render() {
    if (!this.props.marketingPage) {
      return <PageLoader />;
    }

    return (
      <Fragment>
        <PageContent
          pageContent={
            this.props.marketingPage.acf.pageContent
              ? this.props.marketingPage.acf.pageContent
              : undefined
          }
        />
        {/* TODO: add page meta? */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personas: getPersonasSortedAlphabetically(state),
    marketingPage:
      state.marketingPages.pages['persona-listing'] ||
      state.marketingPages.pages['id-persona-listing'] ||
      state.marketingPages.pages['nz-persona-listing'],
    isFetchingMarketingPage: state.marketingPages.isFetching,
    products: state.products,
    isFetchingPersonas: state.personas.isFetching,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      fetchPersonas,
      fetchProducts,
      loadMarketingPage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonaListing);
