// @flow

import React from 'react';
import { Grid } from 'semantic-ui-react';
import Link from './Link';

type Props = {
  megaMenuOpen: boolean,
  onNavigate: () => any,
  singleLinks: Array<Object>
};

class ProductsMegaMenu extends React.Component<Props> {
  render() {
    return (
      <div
        className={`nav-mega-menu ${this.props.megaMenuOpen ? 'nav-mega-menu--open' : ''
          }`}
      >
        <div className="nav-mega-menu__contents">
          <Grid columns={3} className="nav-mega-menu__single-products login">
            {this.props.singleLinks.map((link, i) => (
              <Grid.Column
                key={i}
                as={Link}
                target="_blank"
                url={link.url}
                className="nav-mega-menu__item"
              >
                <div className="nav-mega-menu__link">{link.title}</div>
                <p>{link.strapline}</p>
              </Grid.Column>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

export default ProductsMegaMenu;
